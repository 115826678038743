import { useCallback, useContext, useMemo } from "react";
import PropTypes from 'prop-types';
import DownloadFileButton from "./DownloadFileButton";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useTiposDocFetch } from "src/hooks/useTiposDocFetch";
import { useDocumentosProveedor } from "src/hooks/useDocumentosProveedor";
import { orderBy } from "lodash";
import AppContext from "src/contexts/AppContext";
import { useDocumentosTrabajador } from "src/hooks/useDocumentosTrabajador";

const TablaDocumentos = ({ documentosValidar,personal, setOpenedUpload, habilitado})=>{
	const { tipoDocs } = useTiposDocFetch();
	const { user } = useContext(AppContext);
	const tiposEmpresa = useMemo(()=>(
		personal? tipoDocs?.filter(t=>t.pertenece==='PERSONAL'): 
		tipoDocs?.filter(t=>t.pertenece==='PROVEEDOR')
	),[tipoDocs, personal]);

	const { documentos: documentosEmpresa } = useDocumentosProveedor();
	const {documentosTrabajador} = useDocumentosTrabajador({id: personal});

	const documentos = useMemo(
		() => (personal ? documentosTrabajador : documentosEmpresa),
		[documentosTrabajador,personal , documentosEmpresa]
	);

	const calculaLastDocumentoTipo = useCallback(
		(tipo) => {
			const docs = documentos
				?.filter((d) => d.tipo === tipo.id)
				?.map((d) => ({ ...d, fecha: new Date(d.fecha), caducidad: d?.caducidad ? new Date(d?.caducidad) : null }));
			return docs ? orderBy(docs, 'fecha', 'desc')[0] : null;
		},
		[documentos]
	);

	const datosMostrar = useMemo(() => {
		if (tipoDocs) {
			return tiposEmpresa?.map((tipo) => ({
				...tipo,
				documento: calculaLastDocumentoTipo(tipo),
				// errores: calculaErroresTipo(tipo)
			}));
		}
	}, [calculaLastDocumentoTipo, tipoDocs, tiposEmpresa]);

	

const renderFechaCaducidad = useCallback((row) => {
	const value = row?.documento?.caducidad ? new Date(row?.documento?.caducidad) : null;
	return `${value? value?.toLocaleDateString('es-ES'):''}  `;
}, []);

const renderFechaDoc = useCallback((row, col, la) => {
	const value = row?.documento?.fecha ? new Date(row?.documento?.fecha) : null;
	return `${value ? value?.toLocaleDateString('es-ES') : ''} `;
}, []);


const renderValido = useCallback(
	(row, col) => {
		const value = row.documento && !row.documento?.caducado;
		const tienePendienteValidacion = documentosValidar?.find((d) => d.tipo === row.id);
		const validado = tienePendienteValidacion?.validado;
		const rechazado = tienePendienteValidacion?.rechazado;
		if (tienePendienteValidacion && !validado && !rechazado) {
			return <i className='pi pi-spin pi-cog' style={{ fontSize: '2rem' }} title='Pendiente de validación'></i>;
		}
		return (value||validado) ? (
			<i className='pi pi-check' style={{ color: 'green' }}></i>
		) : (
			<i className='pi pi-times' style={{ color: 'red' }}></i>
		);
	},
	[documentosValidar]
);

const renderError = useCallback(
	(row, col) => {
		const value = row[col.field];
		const tienePendienteValidacion = documentosValidar?.find((d) => d.tipo === row.id);
		if (tienePendienteValidacion ){
			if (tienePendienteValidacion?.rechazado){
				return 'Rechazado, revise en tabla inferior';
			}
			if (!tienePendienteValidacion?.validado){
			return 'Pendiente de validación';
			}
		}
		if (value){
			return `${value} Suba documento`;
		}
		return row.documento? row.documento?.motivo: 'No hay documento';
	},
	[documentosValidar]
);

const renderAccionesDocs = useCallback(
	(row) => {
		const tienePendienteValidacion = documentosValidar?.find((d) => d.tipo === row.id);
		
		return (
			<div>
				{habilitado && !tienePendienteValidacion && row?.documentoBase64 && (
					<DownloadFileButton base64Data={row?.documentoBase64} fileName={row?.nombre} />
				)}
				{habilitado && (!row.documento || row.documento?.caducado) && !tienePendienteValidacion && (
					<Button
						icon='pi pi-upload'
						rounded
						outlined
						onClick={() =>
							setOpenedUpload({
								tipo: row.id,
								tipoNombre: row.tipo,
								id: null,
								datosTipo: row,
								perteneceTrabajador: personal,
								perteneceEmpresa: user?.id,
							})
						}
					/>
				)}
			</div>
		);
	},
	[documentosValidar, habilitado, personal, setOpenedUpload, user?.id]
);

    return (
			<DataTable value={datosMostrar} stripedRows tableStyle={{ minWidth: '50rem' }} size="small">
				<Column field='tipo' header='Documento'></Column>
				<Column field='documento.fecha' header='Fecha' body={renderFechaDoc}></Column>
				<Column field='documento.caducidad' header='Fecha caducidad' body={renderFechaCaducidad}></Column>
				
				<Column field='documento.caducado' header='Valido' body={renderValido}></Column>
				<Column field='documento.motivo' header='Error' body={renderError}></Column>

				<Column header='Acciones' body={renderAccionesDocs}></Column>
			</DataTable>
		);
};

TablaDocumentos.propTypes = {
	documentos: PropTypes.array,
	documentosValidar: PropTypes.array,
	setOpenedUpload: PropTypes.func,
	habilitado: PropTypes.bool
};

export default TablaDocumentos;