import { cloneDeep } from 'lodash';
import { useEffect, useRef } from 'react';

//abajo
//export const WS_URI = 'http://192.168.1.180:8087/geshoras';
//export const WS_URI = 'http://192.168.1.86:8087/portalProveedores';
//torreon
//export const WS_URI = 'http://192.168.1.62:8087/geshoras';
//export const WS_URI = 'http://192.168.1.169:8082/reactivaGestionWS';
// export const WS_URI = 'http://imasat.com:8080/geshoras';
//produccion
export const WS_URI = 'https://ws.reactiva.es/reactivaGestionWS';
export const useArrayMemo = (arrayI) => {
	// this holds reference to previous value
	const ref = useRef();
	const array = arrayI?.filter(a=>a);
	// check if each element of the old and new array match
	const areArraysConsideredTheSame =
		ref.current && array.length === ref.current.length &&
		Array.isArray(array)
			? array?.every((element, i) => {
					return element === ref.current[i];
			}): false;
	useEffect(() => {
		//only update prev results if array is not deemed the same
		if (!areArraysConsideredTheSame) {
			ref.current = array;
		}
	}, [areArraysConsideredTheSame, array]);
	return areArraysConsideredTheSame ? ref.current : array;
};

export const sumaDias = (fecha, dias) => {
	const dd = cloneDeep(fecha);
	dd.setDate(dd.getDate() + dias);
	return dd;
};